// importa os plugins para fazer as requisições para o back
import API from '../plugins/api'

// exporta o store do tema
export default {
    // estado do store de tags
    state: {
        // status do store de tema
        status: null,
        usuario: null,
        theme: {
            light: {
                primary: '#888a8c',
                secondary: '#A4A4A3',
                accent: '#CCC474',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
            }
        }
    },
    // funções para modificar o estado do store de temas
    mutations: {
        // função para pegar o status do store de temas
        SET_STATUS(state, status){
            // seta o status do store de temas
            state.status = status
        },
        // função para pegar o status do store de temas
        SET_THEME(state, theme){
            // seta o store de temas
            state.theme = theme
        },
    },
    // função para as ações do store de temas
    actions: {
        /**
         * @param {*} param0
         */
        async getUserTheme({ commit }){
            // seta o status do store para loading
            await commit('SET_STATUS', 'loading')
            // faz a requisição e armazena na resp
            const resp = await API.get('/theme')
            // caso existam dados
            if(resp.data != undefined){
                // verifica se há cor primaria
                if(!resp.data.light.primary || resp.data.light.primary == ''){
                    // atribui a cor padrão
                    resp.data.light.primary = "#F4D728"
                }
                // verifica se há cor terciaria
                if(!resp.data.light.terciary || resp.data.light.terciary == ''){
                    // atribui a cor padrão
                    resp.data.light.terciary = "#F2D027"
                }
                // verifica se há cor info
                if(!resp.data.light.info || resp.data.light.info == ''){
                    // atribui a cor padrão
                    resp.data.light.info = "#f3ec21"
                }
                // verifica se há cor accent
                if(!resp.data.light.accent || resp.data.light.accent == ''){
                    // atribui a cor padrão
                    resp.data.light.accent = "#fcdf03"
                }
                // verifica se há cor error
                if(!resp.data.light.error || resp.data.light.error == ''){
                    // atribui a cor padrão
                    resp.data.light.error = "#ff4545"
                }
                // verifica se há cor secondary
                if(!resp.data.light.secondary || resp.data.light.secondary == ''){
                    // atribui a cor padrão
                    resp.data.light.secondary = "#A5A4A4"
                }
                // verifica se há cor success
                if(!resp.data.light.success || resp.data.light.success == ''){
                    // atribui a cor padrão
                    resp.data.light.success = "#70cf36"
                }
                // verifica se há cor warning
                if(!resp.data.light.warning || resp.data.light.warning == ''){
                    // atribui a cor padrão
                    resp.data.light.warning = "#fae102"
                }
                // verifica se há cor terciaria
                if(!resp.data.light.primarytabsbackgroud || resp.data.light.primarytabsbackgroud == ''){
                    // atribui a cor padrão
                    resp.data.light.primarytabsbackgroud = "#fc8116"
                }
                // seta o tema no store
                await commit('SET_THEME', resp.data)
            }
            // seta o status do store para vazio
            await commit('SET_STATUS', '')
            // retorna a resposta da requisição
            return resp
        },
    },
    // função para coletar o estado do store
    getters: {
        theme: state => state.theme
    }
}
