// importa o plugin de Request para fazer as requisições para o back
import API from '../plugins/api'
import Axios from 'axios';

// var FormDataSubmit = require('form-data');
// import * as FormDataSubmit from "form-data";
// eporta o store de usuários
export default {
	// estado do store de usuários
	state: {
		// status do store de usuários
		status: null,
		// registro dos usuários
		companies: null,

		workspaces: null,

        company_id: null,

		workspace_id: null,
	},
	// funções para modificar o estado do store de usuários
	mutations: {
		// função para setar o status do store de usuários
		SET_STATUS(state, status) {
			// seta o status do store de usuários
			state.status = status
		},
        SET_COMPANY_ID(state, company_id) {
			// seta o company_id do store de usuários
			state.company_id = company_id
		},
		SET_WORKSPACE_ID(state, workspace_id) {
			// seta o workspace_id do store de usuários
			state.workspace_id = workspace_id
		},
		// função para setar os registros de usuários
		SET_COMPANIES(state, companies) {
			// seta os registros de usuários
			state.companies = companies.companies
		},
		SET_WORKSPACES(state, workspaces) {
			// seta os registros de usuários
			state.workspaces = workspaces.workspaces
		},
	},
	// funções para as ações do store de usuários
	actions: {
		/**
		 * função para coletar os registros dos usuários do back
		 * @param {*} param0 
		 * @param {*} filters 
		 */
		async getCompaniesSelect({ commit }) {
			// seta o status do store de empresas para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			// curl --location --request GET 'http://localhost:3333/api/company?orderBy=id&order=asc&search=finer&_embed=true'
			const { data, status } = await API.get(
				// url da rota do back para fazer a requisição
				'/company?orderBy=name&order=asc&_embed=true', 
			)
			// seta o status do store de empresa para vazio 
			await commit('SET_STATUS', '')
			// caso o status seja 200 (requisiçao foi concluida) 
			if (status == 200) {
				// seta os dados no store
				await commit('SET_COMPANIES', data)
			}
			// retorna a resposta da requisição
			return data
		},

		async putProfile({ commit }, date_update) {
			// seta o status do store de usuarios para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await API.put('/profile/'+date_update.id, date_update.dados_usuario)
			// seta o status do store de usuario para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},

		async getWorkspacesSelect({ commit }) {
			// seta o status do store de empresas para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			// curl --location --request GET 'http://localhost:3333/api/company?orderBy=id&order=asc&search=finer&_embed=true'
			const { data, status } = await API.get(
				// url da rota do back para fazer a requisição
				'/workspace?orderBy=name&order=asc&_embed=true', 
			)
			// seta o status do store de empresa para vazio 
			await commit('SET_STATUS', '')
			// caso o status seja 200 (requisiçao foi concluida) 
			if (status == 200) {
				// seta os dados no store
				await commit('SET_WORKSPACES', data)
			}
			// retorna a resposta da requisição
			return data
		},

        async setWorkspaceId({ commit }, workspace_id) {
			// seta o status do store de empresas para loading
			await commit('SET_STATUS', 'loading')

            if(workspace_id != null) {
                await API.setHeader('app-workspace-id', workspace_id)
            }else{
                await API.deleteHeader('app-workspace-id')
            }

			await commit('SET_WORKSPACE_ID', workspace_id)

			// seta o status do store de empresa para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return true
		},

        async setCompanyId({ commit }, company_id) {
			// seta o status do store de empresas para loading
			await commit('SET_STATUS', 'loading')

            if(company_id != null) {
                await API.setHeader('app-company-id', company_id)
            }else{
                await API.deleteHeader('app-company-id')
            }

			await commit('SET_COMPANY_ID', company_id)

			// seta o status do store de empresa para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return true
		},

		async postImageFile({ commit}, file){
			// seta o status do store de empresas para loading
			await commit('SET_STATUS', 'loading')

            const resp = await API.post(
				// url da rota do back para fazer a requisição
				'/file', file
			)

			// seta o status do store de empresa para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},

		async postImageFileS3({ commit }, {dados, file, fileType }){
			console.log(dados)
			console.log(file)
			commit('SET_STATUS', 'loading')
			let form = new FormData();
			/** para cada chave */
			for(let fieldName of Object.keys(dados.fields)) {
				/** adiciona os campos no form */
				form.append(fieldName, dados.fields[fieldName]);
			}
			form.append('file',file)
			
			
			
			const resp = await Axios.post(dados.url,form,{headers: { 'Content-Type': fileType,'Authorization': ''}})

			// seta o status do store de empresa para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},
	},
	// funções para coletar o estado do store de usuários
	getters: {
		// coleta o status do store de usuários
		status_companies: state => state.status,
		// coleta os registros do store de usuários
		companies: state => state.companies,
		workspaces: state => state.workspaces,
		workspace_id: state => state.workspace_id,

		company_id: state => state.company_id,
	}
}