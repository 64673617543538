// importa o plugin de Api para fazer as requisições para o back
import API from '../plugins/api'

// exporta o store de agenda
export default {
    // estado do store de agenda
    state: {
        // status do store de agenda
        status: null,
        // registro dos clientes
        clientes: null
    },
    // funções para modificar o estado do store de agenda
    mutations: {
        // função para setar o status do store de agenda
        SET_STATUS(state, status){
            // seta o status do store de agenda
            state.status = status
        },
        // função para setar os registros de clientes
        SET_CLIENTES(state, clientes){
            // seta os registros de clientes
            state.clientes = {
                // seta os registros de clientes
                dados: clientes.clients,
                // seta o total de registros de clientes
                total: clientes.total ? clientes.total : (clientes.clients ? clientes.clients.length : 0)
            }
        },
    },
    // funções para as ações do store de agenda
    actions: {
        /**
         * função para coletar os registros de clientes do back
         * @param {*} param0
         * @param {*} filters
         */
        async getClientes({ commit }, filters){
            // seta o status do store de agenda para loading
            await commit('SET_STATUS', 'loading')
            // faz a requisição para o back
            const { data, status } = await API.get(
                // url da rota do back para fazer a requisição
                '/clients' +
                // por qual coluna devem ser ordenados os registros
                (filters.order_column ? ('?orderBy=' + filters.order_column) : '?orderBy=id') +
                // qual a ordem que deve ser ordenado (ASC ou DESC)
                (filters.order_dir ? '&order=' + filters.order_dir : '&order=asc') +
                // qual a quantidade de dados
                (filters.limit ? '&limit=' + filters.limit : '') +
                // qual a página
                (filters.page ? '&page=' + filters.page + '&_embed=false' : '&_embed=true') +
                // os registros que contém a busca solicitada
                (filters.search ? '&search=' + filters.search : ''),
            )
            // seta o status do store de agenda para vazio
            await commit('SET_STATUS', '')
            // caso o status seja 200 (requisiçao foi concluida)
            if(status == 200){
                // seta os dados no store
                await commit('SET_CLIENTES', filters.page ? { clients: data.data, total: data.meta.total } : data)
            }
            // retorna a resposta da requisição
            return data
        },
        /**
         * função para criar um cliente
         * @param {*} param0
         * @param {*} dados_cliente
         */
        async postCliente({ commit }, dados_cliente){
            // seta o status do store de agenda para loading
            await commit('SET_STATUS', 'loading')
            // faz uma requisição para o back e coleta o resultado na variável resp
            const resp = await API.post('/user_client', dados_cliente)
            // seta o status do store de agenda para vazio
            await commit('SET_STATUS', '')
            // retorna a resposta da requisição
            return resp
        },
        /**
         * função para editar os dados de um cliente
         * @param {*} param0
         * @param {*} date_update
         */
        async putCliente({ commit }, date_update){
            // seta o status do store de agenda para loading
            await commit('SET_STATUS', 'loading')
            // faz uma requisição para o back e coleta o resultado na variavel resp
            const resp = await API.put('clients/' + date_update.id, date_update.dados_cliente)
            // seta o status do store de agenda para vazio
            await commit('SET_STATUS', '')
            // retorna a resposta da requisição
            return resp
        },
        /**
         * função para pegar os departamentos da empresa
         * @param {*} param0
         */
        async departamentosEmpresa({ commit }){
            // seta o status do store de agenda para loading
            await commit('SET_STATUS', 'loading')
            // faz uma requisição para o back e coleta o resultado na variável resp
            const resp = await API.get('/departamentosEmpresa')
            // seta o status do store de agenda para loading
            await commit('SET_STATUS', '')
            // retorna a resposta da requisição
            return resp
        },
        /**
         * função para coletar os atendentes do departamento
         * @param {*} param0
         * @param {*} departamento_id
         */
        async atendentesDepartamento({ commit }, departamento_id){
            // seta o status do store de agenda para loading
            await commit('SET_STATUS', 'loading')
            // faz uma requisição para o back e coleta o resultado na variável resp
            const resp = await API.get('/atendentesDepartamento/' + departamento_id)
            // seta o status do store de agenda para loading
            await commit('SET_STATUS', '')
            // retorna a resposta da requisição
            return resp
        }
    },
    // funções para coletar o estado do store de agenda
    getters: {
        // coleta o status do store de agenda
        status_agenda: state => state.status,
        // coleta os registros do store de agenda
        clientes: state => state.clientes,
    }
}
