// importa o plugin de Request para fazer as requisições para o back
import Request from '@/plugins/request'
import API from '../plugins/api'
// import store from '@/store'
// importa o jwt para decodificação do token
// import jwt_decode from "jwt-decode"

export default {
	state: {
		status: null,
		token: localStorage.getItem('token') || null,
		user: null,
		user_id: localStorage.getItem('user_id') || null,
		recorever_email: null,
		app:false
	},
	mutations: {
		SET_STATUS(state, status) {
			state.status = status
		},
		// função para setar o status do store de usuários
		SET_APP(state, app) {
			// seta o app do store de usuários
			state.app = app
		},
		SET_TOKEN(state, token) {
			state.token = token
		},
		SET_USER(state, user) {
			state.user = user
		},
		SET_USER_ID(state, user_id) {
			state.user_id = user_id
		},
		SET_RECOVER_EMAIL(state, email) {
			state.recorever_email = email
		},
		LOGOUT(state) {
			state.status = null
			state.token = null
			state.user = null
		},
	},
	actions: {

		async login({ commit, dispatch }, user) {
			await commit('SET_STATUS', 'loading')

			try{
				const { data, status } = await API.post('/login', {
					email: user.usuario,
					password: user.senha
				})
			
				if (status == 200) {
					if (user.lembrar_usuario) {
						localStorage.setItem('usuario', user.usuario)
						localStorage.setItem('senha', user.senha)
						localStorage.setItem('lembrar_usuario', user.lembrar_usuario)
					} else {
						localStorage.removeItem('usuario')
						localStorage.removeItem('senha')
						localStorage.removeItem('lembrar_usuario')
					}

					API.setAuthorization(data.authenticated.token, true)

					if(data.user.group.key == 'workspace-admin'){
						API.setHeader('app-workspace-id', data.user.workspace_id)
					}

					await commit('SET_TOKEN', data.authenticated.token)
					
					await commit('SET_USER_ID', data.user.id)

					await dispatch('setUser', data.user)
					
					await localStorage.setItem('user_id', data.user.id)
					
					return data

				}else if(status == 204){

					return status

				}
			}catch(e){
				return false
			}
		},

		async setUser({ commit }, user_data) {
			
			await commit('SET_STATUS', 'loading')
			
			if (user_data){
				if (user_data.id) {
					await commit('SET_USER', user_data)
				} else {
					await localStorage.removeItem('token')
				}
				return true
			}
			
			return false
		},

		async setTokenUserApp({ commit }, token) {
			await commit('SET_TOKEN', token)
			API.setAuthorization(token, true)
			await commit('SET_APP',true)
			return true
		},

		async setUserID({ commit, dispatch }) {
			await commit('SET_STATUS', 'loading')

			const { data, status } = await API.get('/me')

			if(status == 200) {
				await dispatch('setUser', data)
				
				await commit('SET_USER_ID', data.id)

				return true
			}else {
				await commit('SET_STATUS', 'error')
				await localStorage.removeItem('token')

				return false
			}
		},

		async logout({ commit }) {
			await commit('LOGOUT')
			await localStorage.removeItem('token')
			return
		},

		async recuperarSenha({ commit }, inputs) {
			await commit('SET_STATUS', 'loading')
			const resp = await Request({
				route: '/usuario/recuperarSenha',
				body: {
					email: inputs.email
				},
				method: 'POST',
			})
		
			if (resp.data.sucesso){
				await commit('SET_RECOVER_EMAIL', resp.data.dados.email)
				await localStorage.setItem('recorever_email', resp.data.dados.email)
			}else{
				await localStorage.removeItem('recorever_email')
				await await commit('SET_STATUS', 'error')
				await commit('SET_RECOVER_EMAIL', '')
			}
			return resp
		},

		async recuperarSenhaValidarCodigo({ commit }, inputs) {
			await commit('SET_STATUS', 'loading')
		
			const resp = await Request({
				route: '/usuario/recuperarSenha/codigo',
				body: {
					codigo: inputs.codigo,
					email: localStorage.getItem('recorever_email'),
				},
				method: 'POST',
			})

			if (resp.data.sucesso) {
				await localStorage.setItem('recover_token', resp.data.dados.token)
			} else {
				await await commit('SET_STATUS', 'error')
				await commit('SET_RECOVER_EMAIL', '')
				await localStorage.removeItem('recorever_email')
				await localStorage.removeItem('recover_token')
			}

			return resp
		},

		async recuperarSenhaNovaSenha({ commit, dispatch }, inputs) {
				
			// axios.defaults.headers.common['Authorization'] = await localStorage.getItem('recover_token')
			
			await commit('SET_STATUS', 'loading')
			
			const resp = Request({
				route: '/usuario/recuperarSenha/nova',
				body: {
					senha: inputs.senha,
					repeteSenha: inputs.repeteSenha,
				},
				method: 'POST',
			})
			
			if (resp.data.sucesso) {
				const resp_login = await dispatch('login', {
					usuario: localStorage.getItem('recorever_email'),
					senha: inputs.senha
				})
				
				if (resp_login.data.sucesso) {
					await localStorage.removeItem('recorever_email')
					await localStorage.removeItem('recover_token')
				}
			}else{
				await await commit('SET_STATUS', 'error')
				await commit('SET_RECOVER_EMAIL', '')
				await localStorage.removeItem('recorever_email')
				await localStorage.removeItem('recover_token')
			}

			return resp
		},
	},
	getters: {
		isLoggedIn: state => !!state.token,
		authStatus: state => state.status,
		token: state => state.token,
		user: state => state.user,
		user_id: state => state.user_id,
		recorever_email: state => state.recorever_email,
		app: state => state.app,
	}
}