// importa o plugin de Request para fazer as requisições para o back
// import Request from '@/plugins/request'
import API from '../plugins/api'


// eporta o store de usuários
export default {
	// estado do store de usuários
	state: {
		// status do store de usuários
		status: null,
		// registro dos usuários
		telefones: null,
	},
	// funções para modificar o estado do store de usuários
	mutations: {
		// função para setar o status do store de usuários
		SET_STATUS(state, status) {
			// seta o status do store de usuários
			state.status = status
		},
		// função para setar os registros de usuários
		SET_TELEFONES(state, telefones) {
			// seta os registros de usuários
			state.telefones = {
				// seta os registros de usuários
				dados: telefones.phones,
				// seta o total registros de usuários
				total: telefones.total ? telefones.total : (telefones.phones ? telefones.phones.length : 0)
			}
		},
	},
	// funções para as ações do store de usuários
	actions: {
		/**
		 * função para coletar os registros dos usuários do back
		 * @param {*} param0 
		 * @param {*} filters 
		 */
		async getTelefones({ commit }, filters) {
			// seta o status do store de telefones para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const { data, status } = await API.get(
				// url da rota do back para fazer a requisição
				'/phone' + 
				// por qual coluna devem ser ordenados os registros
				(filters.order_column ? ('?orderBy=' + filters.order_column) : '?orderBy=id') +
				// qual a ordem que deve ser ordenado (ASC ou DESC)
				(filters.order_dir ? '&order=' + filters.order_dir : '&order=asc') +
				// qual a qtde de dados
				(filters.limit ? '&limit=' + filters.limit : '') +
				// qual a página
				(filters.page ? '&page=' + filters.page + '&_embed=false' : '&_embed=true') +
				// os registros que contem a busca solicitada
				(filters.search ? '&search=' + filters.search : ''), 
			)
			// seta o status do store de telefone para vazio 
			await commit('SET_STATUS', '')
			// caso o status seja 200 (requisiçao foi concluida) 
			if (status == 200) {
				// seta os dados no store
				await commit('SET_TELEFONES', filters.page ? { phones: data.data, total: data.meta.total } : data)
			}
			// retorna a resposta da requisição
			return data
		},

		/**
		 * função para coletar apenas 1 usuário do back
		 * @param {*} param0 
		 * @param {*} telefone_id 
		 */
		async getTelefone({ commit }, telefone_id) {
			// seta o status do store de telefones para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await API.get('/phone/'+telefone_id)
			// seta o status do store de telefone para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},

		/**
		 * função para coletar apenas 1 usuário do back
		 * @param {*} param0 
		 * @param {*} telefone_id 
		 */
		async getQrCode({ commit }, telefone_id) {
			// seta o status do store de telefones para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await API.get('/phone/'+telefone_id + '/qrcode')
			// seta o status do store de telefone para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},
		
		/**
		 * função para criar ou editar 1 usuário
		 * @param {*} param0 
		 * @param {*} dados_telefone 
		 */
		async postTelefone({ commit }, date_phone) {
			// seta o status do store de telefones para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await API.post('/phone', date_phone)
			// seta o status do store de telefone para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},

		/**
		 * função para criar ou editar 1 usuário
		 * @param {*} param0 
		 */
		async getTelefoneSelect({ commit }) {
			// seta o status do store de empresas para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await API.get(
				// url da rota do back para fazer a requisição
				'/phone?orderBy=name&order=asc&_embed=true', 
			)
			// seta o status do store de empresa para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},

		async putPhone({ commit }, date_update) {
			// seta o status do store de usuarios para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await API.put('/phone/'+date_update.id, date_update.dados)
			// seta o status do store de usuario para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},

		async patchPhone({ commit }, date_update) {
			// seta o status do store de usuarios para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await API.patch('/phone/'+date_update.id+'/status', date_update.dados)
			// seta o status do store de usuario para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},
		
		/**
		 * função para criar ou editar 1 usuário
		 * @param {*} param0 
		 */
		 async getApiTypeSelect({ commit }) {
			// seta o status do store de empresas para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await await API.get('/api_type?orderBy=name&order=asc&_embed=true')
			// seta o status do store de empresa para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},
	},
	// funções para coletar o estado do store de usuários
	getters: {
		// coleta o status do store de usuários
		status_telefones: state => state.status,
		// coleta os registros do store de usuários
		telefones: state => state.telefones,
	}
}