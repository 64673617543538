import Vue from 'vue'
import Vuex from 'vuex'

import config from "@/store/config.js"
import login from "@/store/login.js"
import usuarios from "@/store/usuarios.js"
import empresas from "@/store/empresas.js"
import chat from "@/store/chat.js"
import telefones from "@/store/telefones.js"
import grupos from "@/store/grupos.js"
import agendas from "@/store/agendas.js"
import menus from "@/store/menus.js"
import setores from "@/store/setores.js"
import menus_telefone from "@/store/menus_telefone.js"
import application from "@/store/application.js"
import bot from "@/store/bot.js"
import theme from "@/store/theme.js"

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		config,
		login,
		usuarios,
		empresas,
		chat,
		telefones,
		grupos,
		agendas,
		menus,
		setores,
		menus_telefone,
		bot,
		application,
		theme,
	},
})