import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import API from '../plugins/api'

Vue.use(VueRouter)

function setApp(to){
	return new Promise(function (resolve) {
		console.log(to)
		if(to.query.token && (to.query.app || (to.meta.acesso_externo != undefined))){
			store.dispatch('setTokenUserApp', to.query.token).then((retorno)=>{
				console.log(retorno)
				resolve()
			})
		}else{
			resolve()
		}
	});	

}
function verificaLogin(to, from, next) {
	if (store.getters.isLoggedIn) {
		store.dispatch('setUserID').then(function () {
			if (store.getters.user_id) {
				if(to.name == 'Chat'){
					verificaGroupChat(to, from, next, 4)
				}else{
					next()
					return
				}
			} else {
				next('/login')
			}
		})
	} else {
		next('/login')
	}
}

function verificaRota(to, from, next) {
	if (store.getters.isLoggedIn) {
		store.dispatch('setUser').then(function () {
		
			store.dispatch('verificaMenuByUsuario',to.path).then(function (retorno) {
				if(retorno){
					next()
					return
				}else{
					next('/sem-permissao')
					return
				}
			})	
		})
	} else {
		next('/login')
	}
}

async function verificaGroupChat(to, from, next, requisito){
	// if(status == 200){
		if(store.getters.user.group.reference_id < requisito){
			next('/sem-permissao')
		// }else if (store.getters.workspace_id == null ){
		// 	next('/')
		}else{
			next()
		}
	// }else{
	// 	next('/')
	// }
}

async function verificaGroup(to, from, next, requisito) {
	if (store.getters.isLoggedIn) {
		const { data, status } = await API.get('user-group?orderBy=id&order=asc&_embed=true')
		if(status == 200){
			if(((data.user_groups).find(element => element.reference_id <= requisito)) == undefined){
				next('/sem-permissao')
			// }else if (store.getters.workspace_id == null ){
			// 	next('/')
			}else{
				next()
			}
		}else{
			next('/')
		}
	} else {
		next('/login')
	}
}

// function verificaWorkspaceAdmin(to, from, next) {
// 	if (store.getters.isLoggedIn) {
// 		if (store.getters.user.group.key != 'super' && store.getters.user.group.key != 'workspace-admin'){
// 			next('/sem-permissao')
// 		}else{
// 			next()
// 		}
// 	} else {
// 		next('/login')
// 	}
// }

// function verificaCompanyAdmin(to, from, next) {
// 	if (store.getters.isLoggedIn) {
// 		if (store.getters.user.group.key != 'super' && store.getters.user.group.key != 'workspace-admin' && store.getters.user.group.key != 'company-admin'){
// 			next('/sem-permissao')
// 		}else{
// 			next()
// 		}
// 	} else {
// 		next('/login')
// 	}
// }

function notLogin(to, from, next) {
	if (!store.getters.isLoggedIn) {
		next()
		return
	} else {
		next('/')
	}
}

function notLoginRecover(to, from, next) {
	if (!store.getters.isLoggedIn && localStorage.getItem('recover_token')) {
		next()
		return
	} else {
		next('/recuperar-senha')
	}
}

function deslogar(to, from, next) {
	store.dispatch('logout').then(function () {
		next('/login')
	})
}

const routes = [
	{
		path: '/',
		name: 'Dashboard',
		component: () => import('@/views/Dashboard.vue'),
		beforeEnter(to, from, next) {
			verificaLogin(to, from, next)
		},
		children: [
			{
				path: '/dashboard',
				name: 'Pages Dashboard',
				beforeEnter(to, from, next) {
					verificaGroup(to, from, next, 4)
				},
				component: () => import('@/views/pages/PageDashboard.vue'),
			},
			{
				path: '/usuarios',
				name: 'Usuários',
				beforeEnter(to, from, next) {
					verificaGroup(to, from, next, 4)
				},
				component: () => import('@/views/pages/Usuarios.vue'),
			},
			{
				path: '/empresas',
				name: 'Empresas',
				beforeEnter(to, from, next) {
					verificaGroup(to, from, next, 2)
				},
				component: () => import('@/views/pages/Empresas.vue'),
			},
			{
				path: '/telefones',
				name: 'Telefones',
				beforeEnter(to, from, next) {
					verificaGroup(to, from, next, 4)
				},
				component: () => import('@/views/pages/Telefones.vue'),
			},
			{
				path: '/agendas',
				name: 'Agendas',
				beforeEnter(to, from, next){
					verificaGroup(to, from, next, 6)
				},
				component: () => import('@/views/pages/Agenda.vue'),
			},
			{
				path: '/grupos',
				name: 'Grupos',
				beforeEnter(to, from, next) {
					verificaRota(to, from, next)
				},
				component: () => import('@/views/pages/Grupos.vue'),
			},
			{
				path: '/menus',
				name: 'Menus',
				beforeEnter(to, from, next) {
					verificaRota(to, from, next)
				},
				component: () => import('@/views/pages/Menus.vue'),
			},
			{
				path: '/menus_telefone',
				name: 'menus_telefone',
				beforeEnter(to, from, next) {
					verificaRota(to, from, next)
				},
				component: () => import('@/views/pages/MenusTelefones.vue'),
			},
			{
				path: '/setores',
				name: 'Setores',
				beforeEnter(to, from, next) {
					verificaGroup(to, from, next, 4)
				},
				component: () => import('@/views/pages/Setores.vue'),
			},
			{
				path: '/chat_setores',
				name: 'ChatSetores',
				beforeEnter(to, from, next) {
					verificaGroup(to, from, next, 4)
				},
				component: () => import('@/views/pages/ChatSetores.vue'),
			},
			{
				path: '/chat_usuarios/:id',
				name: 'ChatUsuarios',
				props: true,
				beforeEnter(to, from, next) {
					verificaGroup(to, from, next, 4)
				},
				component: () => import('@/views/pages/ChatUsuarios.vue'),
			},
			{
				path: '/bot',
				name: 'Bot',
				beforeEnter(to, from, next) {
					verificaGroup(to, from, next, 4)
				},
				component: () => import('@/views/pages/Bot.vue'),
			},
			{
				path: '/sem-permissao',
				name: 'Sem Permissão',
				component: () => import('@/views/pages/SemPermissao.vue'),
			},
		]
	},
	{
		path: '/chat/:id',
		name: 'Chat',
		beforeEnter(to, from, next) {
			setApp(to).then(()=>{
				verificaLogin(to, from, next)
			})
		},
		component: () => import('@/views/Chat.vue'),
	},
	{
		path: '/chat_externo/:chat_id',
		name: 'Chat',
		meta:{
			acesso_externo:true
		},
		beforeEnter(to, from, next) {
			setApp(to).then(()=>{
				verificaLogin(to, from, next)
			})
		},
		component: () => import('@/views/Chat.vue'),
	},
	{
		path: '/logout',
		beforeEnter(to, from, next) {
			deslogar(to, from, next)
		}
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('@/views/login/Login.vue'),
		beforeEnter(to, from, next) {
			notLogin(to, from, next)
		}
	},
	{
		path: '/recuperar-senha',
		name: 'Esqueci minha senha',
		component: () => import('@/views/login/RecuperarSenha.vue'),
		beforeEnter(to, from, next) {
			notLogin(to, from, next)
		}
	},
	{
		path: '/validar-codigo',
		name: 'Validar código',
		component: () => import('@/views/login/RecuperarSenhaCodigo.vue'),
		beforeEnter(to, from, next) {
			notLoginRecover(to, from, next)
		}
	},
	{
		path: '/nova-senha',
		name: 'Nova senha',
		component: () => import('@/views/login/NovaSenha.vue'),
		beforeEnter(to, from, next) {
			notLoginRecover(to, from, next)
		}
	},
	{
		path: '/privacidade',
		name: 'Políticas de privacidade',
		component: () => import('@/views/PoliticasDePrivacidade.vue'),
	},
	{
		path: '*',
		name: '404',
		component: () => import('@/views/pages/404.vue'),
	},
]

export default new VueRouter({
	routes,
	linkExactActiveClass: 'active',
	mode: 'history'
})