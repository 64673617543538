// importa o plugin de Request para fazer as requisições para o back
import Request from '@/plugins/request'
import API from '../plugins/api'

// eporta o store de usuários
export default {
	// estado do store de usuários
	state: {
		// status do store de usuários
		status: null,
		// registro dos usuários
		empresas: null,
	},
	// funções para modificar o estado do store de usuários
	mutations: {
		// função para setar o status do store de usuários
		SET_STATUS(state, status) {
			// seta o status do store de usuários
			state.status = status
		},
		// função para setar os registros de usuários
		SET_EMPRESA(state, empresas) {
			// seta os registros de usuários
			state.empresas = {
				// seta os registros de usuários
				dados: empresas.companies,
				// seta o total registros de usuários
				total: empresas.total ? empresas.total : (empresas.companies ? empresas.companies.length : 0)
			}
		},
	},
	// funções para as ações do store de usuários
	actions: {
		/**
		 * função para coletar os registros dos usuários do back
		 * @param {*} param0 
		 * @param {*} filters 
		 */
		async getEmpresas({ commit }, filters) {
			// seta o status do store de empresas para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			// curl --location --request GET 'http://localhost:3333/api/company?orderBy=id&order=asc&search=finer&_embed=true'
			const { data, status } = await API.get(
				// url da rota do back para fazer a requisição
				'/company' + 
				// por qual coluna devem ser ordenados os registros
				(filters.order_column ? ('?orderBy=' + filters.order_column) : '?orderBy=id') +
				// qual a ordem que deve ser ordenado (ASC ou DESC)
				(filters.order_dir ? '&order=' + filters.order_dir : '&order=asc') +
				// qual a qtde de dados
				(filters.limit ? '&limit=' + filters.limit : '') +
				// qual a página
				(filters.page ? '&page=' + filters.page + '&_embed=false' : '&_embed=true') +
				// os registros que contem a busca solicitada
				(filters.search ? '&search=' + filters.search : ''), 
			)
			// seta o status do store de empresa para vazio 
			await commit('SET_STATUS', '')
			// caso o status seja 200 (requisiçao foi concluida) 
			if (status == 200) {
				// seta os dados no store
				await commit('SET_EMPRESA', filters.page ? { companies: data.data, total: data.meta.total } : data)
			}
			// retorna a resposta da requisição
			return data
		},

		/**
		 * função para coletar apenas 1 usuário do back
		 * @param {*} param0 
		 * @param {*} empresa_id 
		 */
		async getEmpresa({ commit }, empresa_id) {
			// seta o status do store de empresas para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await API.get('/company/' + empresa_id)
			// seta o status do store de empresa para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},

		/**
		 * função para coletar apenas 1 usuário do back
		 * @param {*} param0 
		 * @param {*} empresa_id 
		 */
		async getConfigMenuEmpresa({ commit }, empresa_id) {
			// seta o status do store de empresas para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: '/configuracoes_menu/getByEmpresa/' + empresa_id,
			})
			// seta o status do store de empresa para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},

		/**
		 * função para coletar apenas 1 usuário do back
		 * @param {*} param0 
		 * @param {*} empresa_id 
		 */
		async getConfigNomeEmpresa({ commit }, empresa_id) {
			// seta o status do store de empresas para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: '/configuracoes_nome/getByEmpresa/' + empresa_id,
			})
			// seta o status do store de empresa para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},

		/**
		 * função para criar ou editar 1 usuário
		 * @param {*} param0 
		 * @param {*} dados_empresa 
		 */
		async postEmpresas({ commit }, dados_empresa) {
			// seta o status do store de empresas para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await API.post(
				// url da rota do back para fazer a requisição
				'/company',
				// corpo da requisição
				dados_empresa,
			)
			// seta o status do store de empresa para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},

		/**
		 * função para criar ou editar 1 usuário
		 * @param {*} param0 
		 * @param {*} date_update 
		 */
		async putEmpresas({ commit }, date_update) {
			// seta o status do store de empresas para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await API.put(
				// url da rota do back para fazer a requisição
				'/company/'+date_update.id,
				// corpo da requisição
				date_update.dados_empresa,
			)
			// seta o status do store de empresa para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},

		/**
		 * função para criar ou editar 1 usuário
		 * @param {*} param0 
		 * @param {*} dados_config_menu 
		 */
		async postConfigMenuEmpresa({ commit }, dados_config_menu) {
			// seta o status do store de empresas para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: '/configuracoes_menu/post/',
				// corpo da requisição
				body: dados_config_menu,
				// método da requição(POST normalmente é usado para salvar dados do back)
				method: 'POST'
			})
			// seta o status do store de empresa para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},

		/**
		 * função para criar ou editar 1 usuário
		 * @param {*} param0 
		 * @param {*} dados_config_nome 
		 */
		async postConfigNomeEmpresa({ commit }, dados_config_nome) {
			// seta o status do store de empresas para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: '/configuracoes_nome/post/',
				// corpo da requisição
				body: dados_config_nome,
				// método da requição(POST normalmente é usado para salvar dados do back)
				method: 'POST'
			})
			// seta o status do store de empresa para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},


		/**
		 * função para criar ou editar 1 usuário
		 * @param {*} param0 
		 * @param {*} dados_empresa 
		 */
		async getEmpresaSelect({ commit }) {
			// seta o status do store de empresas para loading
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: '/empresa/read',
				method: 'GET'
			})
			// seta o status do store de empresa para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},

		/**
         * função para pegar os departamentos da empresa
         * @param {*} param0
         * @param {*} company_id
         */
        async departmentCompany({ commit }, company_id){
            // seta o status do store de agenda para loading
            await commit('SET_STATUS', 'loading')
            // faz uma requisição para o back e coleta o resultado na variável resp
            const resp = await API.get('/departmentCompany/' + company_id)
            // seta o status do store de agenda para loading
            await commit('SET_STATUS', '')
            // retorna a resposta da requisição
            return resp
        },
	},
	// funções para coletar o estado do store de usuários
	getters: {
		// coleta o status do store de usuários
		status_empresas: state => state.status,
		// coleta os registros do store de usuários
		empresas: state => state.empresas,
	}
}