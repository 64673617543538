// importa o plugin de request
import Request from "@/plugins/request";
import API from "../plugins/api";

export default {
  state: {
    chat_empresas: [],
    chat_conversas: [],
    chat_mensagens: [],
    chat_status: "",
    rooms: [],
    users: [],
    messages: [],
  },
  mutations: {
    // função para setar o status do store de usuários
    SET_STATUS(state, status) {
      // seta o status do store de usuários
      state.chat_status = status;
    },
    // função para setar os registros de usuários
    SET_EMPRESAS(state, dados) {
      // seta os registros de usuários
      state.chat_empresas = dados;
    },
    // função para setar os registros de usuários
    // SET_CHATS(state, dados) {
    // 	// // seta os registros de usuários
    //     // this.ATUALIZA_QUANTIDADE_CHAT(state,dados.notificacoes,dados.conversas)

    // },
    // função para setar os registros de usuários
    SET_MENSAGENS(state, dados) {
      // seta os registros de usuários
      state.chat_mensagens = dados;
    },
    SET_ROOMS(state, rooms) {
      // seta os registros de usuários
      state.rooms = rooms;
    },
    SET_USERS(state, users) {
      //seta os registros de usuários
      state.users = users;
    },
    SET_MESSAGES(state, messages) {
      // seta os registros de usuários
      state.messages = messages;
    },
    // função para setar os registros de usuários
    ADD_MENSAGENS(state, mensagem) {
      var mensagens = state.chat_mensagens;
      // seta os registros de usuários
      mensagens.push(mensagem);

      state.chat_mensagens = mensagens;
    },
    // função para setar os registros de usuários
    ADD_CHAT(state, chat) {
      var conversas = state.chat_conversas;
      // seta os registros de usuários
      conversas.push(chat);

      state.chat_conversas = conversas;
    },
    ATUALIZA_QUANTIDADE_CHAT(state, dados) {
      let tmp = [];
      for (let index = 0; index < dados.notificacoes.length; index++) {
        let element = dados.notificacoes[index];

        if (["chat", "mensagem"].includes(element.tipo_notificacao)) {
          if (tmp[element.valor_coluna] == undefined) {
            tmp[element.valor_coluna] = 0;
          }

          tmp[element.valor_coluna]++;
        }
      }

      for (let index2 = 0; index2 < dados.conversas.length; index2++) {
        let element2 = dados.conversas[index2];

        dados.conversas[index2]["quantidade_mensagens_n_visualizadas"] =
          tmp[element2._id] == undefined ? 0 : tmp[element2._id];
      }
      state.chat_conversas = dados.conversas;
    },
  },
  actions: {
    async getRooms({ commit }, filters) {
      // seta o status do store de chat para loading
      await commit("SET_STATUS", "loading");
      // faz a requisição para o back para coletar as empresas
      const { data, status } = await API.get(
        // rota da requisição
        "/room" +
          (filters.search ? "?search=" + filters.search : "?search=") +
          (filters.single_room ? "&single_room=" + filters.single_room : "") +
          (filters.user_chat_id ? "&user_chat_id=" + filters.user_chat_id : "") +
          (filters.end_date ? "&end_date=" + filters.end_date : "") +
          (filters.start_date ? "&start_date=" + filters.start_date : "") +
          (filters.closed ? "&closed=" + filters.closed : "") +
          (filters.tags ? "&tags=" + filters.tags : "")//+
          // (filters.paginate ? "&paginate=" + filters.paginate : "")
      );
      // caso o status da resposta seja 200 (deu certo)
      if (status == 200) {
        // atribui a resposta na variavel empresas
        await commit("SET_ROOMS", data);
      }
      // seta o status do store de chat
      await commit("SET_STATUS", "");
      return data;
    },

    async getMessages({ commit }, dados) {
      // seta o status do store de chat para loading
      await commit("SET_STATUS", "loading");
      let params = {}

      if(dados.message){
        params['last_message_id'] = dados.message
      }
      if(dados.user_chat_id){
        params['user_chat_id'] = dados.user_chat_id
      }
      if(dados.single_room){
        params['single_room'] = dados.single_room
      }
      // faz a requisição para o back para coletar as empresas
      const { data, status } = await API.get(
        // rota da requisição
        "/message/" +
          dados.id , params
      );
      // caso o status da resposta seja 200 (deu certo)
      if (status == 200) {
        // atribui a resposta na variavel empresas
        await commit("SET_MESSAGES", data);
      }
      // seta o status do store de chat
      await commit("SET_STATUS", "");
      return data;
    },
    async getMessageReply({ commit }, message_reply_id) {
      // seta o status do store de chat para loading
      await commit("SET_STATUS", "loading");
      // faz uma requisição para o back e coleta o resultado na variável resp
      const resp = await API.get("/messageReply/" + message_reply_id);
      // seta o status do store de chat para vazio
      await commit("SET_STATUS", "");
      // retorna a resposta da requisição
      return resp.data;
    },
    async adicionarConversa({ commit }, dados) {
      // seta o status do store de chat para loading
      await commit("SET_STATUS", "loading");
      // faz a requisição para o back para coletar as empresas
      const resp = await API.post(
        // rota da requisição
        "/room/create",
        dados
      );

      // seta o status do store de chat
      await commit("SET_STATUS", "");

      return resp;
    },
    async adicionarTag({ commit }, dados) {
      // seta o status do store de chat para loading
      await commit("SET_STATUS", "loading");
      // faz a requisição para o back para coletar as empresas
      const resp = await API.post(
        // rota da requisição
        "/room/tag/create",
        dados
      );
      // seta o status do store de chat
      await commit("SET_STATUS", "");

      return resp;
    },

    async adicionarUsuarioClient({ commit }, dados) {
      // seta o status do store de chat para loading
      await commit("SET_STATUS", "loading");
      // faz a requisição para o back para coletar as empresas
      const resp = await API.post(
        // rota da requisição
        "/user_client",
        dados
      );
      // seta o status do store de chat
      await commit("SET_STATUS", "");

      return resp;
    },

    /**
     * função para coletar apenas 1 usuário do back
     * @param {*} param0
     * @param {*} usuario_id
     */
    async getRoom({ commit }, usuario_id) {
      // seta o status do store de usuarios para loading
      await commit("SET_STATUS", "loading");
      // faz uma requisição para o back e coleta o resultado na variavel resp
      const resp = await API.get("/room/" + usuario_id);
      // seta o status do store de usuario para vazio
      await commit("SET_STATUS", "");
      // retorna a resposta da requisição
      return resp;
    },

    async putPerfil({ commit }, date_update) {
      // seta o status do store de usuarios para loading
      await commit("SET_STATUS", "loading");
      // faz uma requisição para o back e coleta o resultado na variavel resp
      const resp = await API.put("/room/" + date_update.id, date_update.dados_room);
      // seta o status do store de usuario para vazio
      await commit("SET_STATUS", "");
      // retorna a resposta da requisição
      return resp;
    },
    async atendimentosAbertos({ commit }, filters) {
      //seta o status do store para loading
      await commit("SET_STATUS", "loading");
      //faz a requisição para o back para coletar os dados
      const { data, status } = await API.get(
        "/abertos" +
          (filters.setores ? "?department_id=" + filters.setores : "") +
          (filters.atendente ? "&user_id=" + filters.atendente : "") +
          (filters.end_date ? "&end_date=" + filters.end_date : "") +
          (filters.start_date ? "&start_date=" + filters.start_date : "")
      );
      //caso o status da resposta seja 200 (deu certo)
      if (status == 200) {
        // atribui a resposta à variavel
        await commit("SET_USERS", data);
      }
      // seta o status do store de chat
      await commit("SET_STATUS", "");
      //retorna a resposta da requisição
      return data;
    },
    async atendimentosFechados({ commit }, filters) {
      //seta o status do store para loading
      await commit("SET_STATUS", "loading");
      //faz a requisição para o back para coletar os dados
      const { data, status } = await API.get(
        "/fechados" +
          (filters.setores ? "?department_id=" + filters.setores : "") +
          (filters.atendente ? "&user_id=" + filters.atendente : "") +
          (filters.end_date ? "&end_date=" + filters.end_date : "") +
          (filters.start_date ? "&start_date=" + filters.start_date : "")
      );
      //caso o status da resposta seja 200 (deu certo)
      if (status == 200) {
        // atribui a resposta à variavel
        await commit("SET_USERS", data);
      }
      // seta o status do store de chat
      await commit("SET_STATUS", "");
      //retorna a resposta da requisição
      return data;
    },
    async atendimentosTotais({ commit }, filters) {
      //seta o status do store para loading
      await commit("SET_STATUS", "loading");
      //faz a requisição para o back para coletar os dados
      const { data, status } = await API.get(
        "/totais" +
          (filters.setores ? "?department_id=" + filters.setores : "") +
          (filters.end_date ? "&end_date=" + filters.end_date : "") +
          (filters.start_date ? "&start_date=" + filters.start_date : "")
      );
      //caso o status da resposta seja 200 (deu certo)
      if (status == 200) {
        // atribui a resposta à variavel
        await commit("SET_USERS", data);
      }
      // seta o status do store de chat
      await commit("SET_STATUS", "");
      //retorna a resposta da requisição
      return data;
    },
    async atendimentosAtivos({ commit }, filters) {
      //seta o status do store para loading
      await commit("SET_STATUS", "loading");
      //faz a requisição para o back para coletar os dados
      const { data, status } = await API.get(
        "/ativos" +
          (filters.setores ? "?department_id=" + filters.setores : "") +
          (filters.end_date ? "&end_date=" + filters.end_date : "") +
          (filters.start_date ? "&start_date=" + filters.start_date : "")
      );
      //caso o status da resposta seja 200 (deu certo)
      if (status == 200) {
        // atribui a resposta à variavel
        await commit("SET_USERS", data);
      }
      // seta o status do store de chat
      await commit("SET_STATUS", "");
      //retorna a resposta da requisição
      return data;
    },
    async aguardandoAtendimento({ commit }, filters) {
      //seta o status do store para loading
      await commit("SET_STATUS", "loading");
      //faz a requisição para o back para coletar os dados
      const { data, status } = await API.get(
        "/aguardandoA" +
          (filters.setores ? "?department_id=" + filters.setores : "") +
          (filters.end_date ? "&end_date=" + filters.end_date : "") +
          (filters.start_date ? "&start_date=" + filters.start_date : "")
      );
      //caso o status da resposta seja 200 (deu certo)
      if (status == 200) {
        // atribui a resposta à variavel
        await commit("SET_USERS", data);
      }
      // seta o status do store de chat
      await commit("SET_STATUS", "");
      //retorna a resposta da requisição
      return data;
    },
    async atendimentosFinalizados({ commit }, filters) {
      //seta o status do store para loading
      await commit("SET_STATUS", "loading");
      //faz a requisição para o back para coletar os dados
      const { data, status } = await API.get(
        "/finalizados" +
          (filters.setores ? "?department_id=" + filters.setores : "") +
          (filters.end_date ? "&end_date=" + filters.end_date : "") +
          (filters.start_date ? "&start_date=" + filters.start_date : "")
      );
      //caso o status da resposta seja 200 (deu certo)
      if (status == 200) {
        // atribui a resposta à variavel
        await commit("SET_USERS", data);
      }
      // seta o status do store de chat
      await commit("SET_STATUS", "");
      //retorna a resposta da requisição
      return data;
    },
    async duracaoAtendimentos({ commit }, filters) {
      //seta o status do store para loading
      await commit("SET_STATUS", "loading");
      //faz a requisição para o back para coletar os dados
      const { data, status } = await API.get(
        "/duracaoA" + (filters.setores ? "?department_id=" + filters.setores : "")
      );
      //caso o status da resposta seja 200 (deu certo)
      if (status == 200) {
        // atribui a resposta à variavel
        await commit("SET_USERS", data);
      }
      // seta o status do store de chat
      await commit("SET_STATUS", "");
      //retorna a resposta da requisição
      return data;
    },
    async atendimentosAtendente({ commit }, filters) {
      //seta o status do store para loading
      await commit("SET_STATUS", "loading");
      //faz a requisição para o back para coletar os dados
      const { data, status } = await API.get(
        "/totalA" +
          (filters.setores ? "?department_id=" + filters.setores : "") +
          (filters.atendente ? "&user_id=" + filters.atendente : "") +
          (filters.end_date ? "&end_date=" + filters.end_date : "") +
          (filters.start_date ? "&start_date=" + filters.start_date : "")
      );
      //caso o status da resposta seja 200 (deu certo)
      if (status == 200) {
        // atribui a resposta à variavel
        await commit("SET_USERS", data);
      }
      // seta o status do store de chat
      await commit("SET_STATUS", "");
      //retorna a resposta da requisição
      return data;
    },
    /**
     * Função para coletar a duração média de atendimento passando a atendente
     * @param {*} param0
     * @param {*} dados
     */
    async mediaAtendente({ commit }, dados) {
      //seta o status do store para loading
      await commit("SET_STATUS", "loading");
      //faz a requisição para o back para coletar o resultado na variável resp
      const resp = await API.post("/duracaoAtendente", dados);
      // seta o status do store da room para vazio
      await commit("SET_STATUS", "");
      // retorna a resposta da requisição
      return resp;
    },
    /**
     * função para a atendente passando o departamento
     * @param {*} param0
     * @param {*} dados
     */
    async getAtendente({ commit }, dados) {
      // seta o status do store da room para loading
      await commit("SET_STATUS", "loading");
      // faz uma requisição para o back e coleta o resultado na variável resp
      const resp = await API.post("/departmentUser", dados);
      // seta o status do store da room para vazio
      await commit("SET_STATUS", "");
      // retorna a resposta da requisição
      return resp;
    },
    /**
     * Função para coletar os registros de tags do back
     * @param {*} param0
     * @param {*} dados_tags
     */
    async getTags({ commit }, dados_tags) {
      // seta o status do store de rooms para loading
      await commit("SET_STATUS", "loading");
      // faz a requisição para o back e coleta o resultado na variável resp
      const resp = await API.post("roomTags", dados_tags);
      // seta o status do store de rooms para vazio
      await commit("SET_STATUS", "");
      // retorna a resposta da requisição
      return resp;
    },
    /**
     * Função para buscar as tags para a busca
     * @param {*} param0
     * @param {*} dados
     */
    async searchTags({ commit }, dados) {
      // seta o status do store de rooms para loading
      await commit("SET_STATUS", "loading");
      // faz a requisição para o back para coleta o resultado na variável resp
      const resp = await API.get("/roomTags?orderBy=id&order=asc&search=" + dados.busca, dados);
      // seta o status do store de rooms para vazio
      await commit("SET_STATUS", "");
      // retorna a resposta da requisição
      return resp;
    },
    // função para editar os dados de uma conversa
    async putTag({ commit }, date_update) {
      // seta o status do store de room para loading
      await commit("SET_STATUS", "loading");
      // faz uma requisição para o back e coleta o resultado na variável resp
      const resp = await API.put("/roomTag/" + date_update.id, date_update.dados_tag);
      // seta o status do store de room para vazio
      await commit("SET_STATUS", "");
      // retorna a resposta da requisição
      return resp;
    },
    /**
     * função para coletar as tags de cada sala do back
     * @param {*} param0
     * @param {*} chat_id
     */
    async roomTag({ commit }, chat_id) {
      // seta o status do store de chat para loading
      await commit("SET_STATUS", "loading");
      // faz uma requisição para o back e coleta o resultado na variável resp
      const resp = await API.get("/tagsRoom/" + chat_id);
      // seta o status do store de chat para vazio
      await commit("SET_STATUS", "");
      // retorna a resposta da requisição
      return resp;
    },
    async takeOverRoom({ commit }, room_id) {
      // seta o status do store de chat para loading
      await commit("SET_STATUS", "loading");
      // faz a requisição para o back para coletar as empresas
      const resp = await API.post(
        // rota da requisição
        "/room/take-over",
        { room_id }
      );
      // seta o status do store de chat
      await commit("SET_STATUS", "");

      return resp;
    },
    async transferRoom({ commit }, dados) {
      // seta o status do store de chat para loading
      await commit("SET_STATUS", "loading");
      // faz a requisição para o back para coletar as empresas
      const resp = await API.post(
        // rota da requisição
        "/room/transfer",
        dados
      );
      // seta o status do store de chat
      await commit("SET_STATUS", "");

      return resp;
    },
    async closeRoom({ commit }, room_id) {
      // seta o status do store de chat para loading
      await commit("SET_STATUS", "loading");
      // faz a requisição para o back para coletar as empresas
      const resp = await API.post(
        // rota da requisição
        "/room/" + room_id + "/close"
      );
      // seta o status do store de chat
      await commit("SET_STATUS", "");

      return resp;
    },
    // função para pegar as empresas
    async getChatEmpresas({ commit }) {
      // seta o status do store de chat para loading
      await commit("SET_STATUS", "loading");
      // faz a requisição para o back para coletar as empresas
      var resp = await Request({
        // rota da requisição
        route: "/empresa/read",
      });
      // caso o status da resposta seja 200 (deu certo)
      if (resp.status == 200) {
        // atribui a resposta na variavel empresas
        await commit("SET_EMPRESAS", resp.data);
      }
      // seta o status do store de chat
      await commit("SET_STATUS", "");
      return resp;
    },
    // função para enviar mensagem
    async enviaMensagemChat({ commit }, dados_post) {
      // seta o status do store de chat para loading
      await commit("SET_STATUS", "loading");
      // faz a requisição para o back para coletar as empresas
      var resp = await Request({
        // rota da requisição
        route: "/conversa/enviarMensagem",
        body: dados_post,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data;  boundary=" + dados_post._boundary,
        },
      });
      // seta o status do store de chat
      await commit("SET_STATUS", "");
      return resp.status == "200" ? resp.data : { sucesso: false };
    },
    async setNewMessageChat({ commit }, data) {
      await commit("SET_STATUS", "loading");

      console.log(data);

      await commit("SET_STATUS", "");
    },
    async setNewMessageChatList({ commit }, data) {
      await commit("SET_STATUS", "loading");

      const message = {
        content: data.content,
        created_at: data.created_at,
        message_type: data.message_type,
        send_by: data.name,
      };

      await commit("SET_STATUS", "");

      return message;
    },
    // função para pegar as empresas
    async getChatCoversas({ commit }, empresa_id) {
      // seta o status do store de chat para loading
      await commit("SET_STATUS", "loading");
      // faz a requisição para o back para coletar as empresas
      // var resp = await Request({
      //     // rota da requisição
      //     route: '/converas/read/' + empresa_id
      // })
      // // caso o status da resposta seja 200 (deu certo)
      // if(resp.status == 200){
      //     // atribui a resposta na variavel empresas
      //     await commit('SET_EMPRESAS', resp.data)
      // }
      // seta o status do store de chat
      await commit("SET_STATUS", "");
      return empresa_id;
    },
    async getEmpresasUsuarioSelect({ commit }, usuario) {
      // seta o status do store de empresas para loading
      await commit("SET_STATUS", "loading");
      // faz uma requisição para o back e coleta o resultado na variavel resp
      const resp = await Request({
        // url da rota do back para fazer a requisição
        route: "/chat/getEmpresaSelect/?" + "usuario=" + usuario,
      });
      // seta o status do store de empresa para vazio
      await commit("SET_STATUS", "");
      // retorna a resposta da requisição
      return resp;
    },

    /**
     * função para criar ou editar 1 usuário
     * @param {*} param0
     */
    async getSetoresSelectRoom({ commit }, company_id) {
      // seta o status do store de empresas para loading
      await commit("SET_STATUS", "loading");
      // faz uma requisição para o back e coleta o resultado na variavel resp
      const resp = await await API.get("/department?company_id=" + company_id);

      await API.setHeader("app-company-id", company_id);
      // seta o status do store de empresa para vazio
      await commit("SET_STATUS", "");
      // retorna a resposta da requisição
      return resp;
    },

    async getUsuarioSelectRoom({ commit }, department_id) {
      // seta o status do store de usuarios para loading
      await commit("SET_STATUS", "loading");
      // faz uma requisição para o back e coleta o resultado na variavel resp
      const resp = await API.get(
        // url da rota do back para fazer a requisição
        "/user?department_id=" + department_id
      );
      // seta o status do store de usuario para vazio
      await commit("SET_STATUS", "");
      // retorna a resposta da requisição
      return resp;
    },

    async getChatUsuarioSelect({ commit }, empresa) {
      await commit("SET_STATUS", "loading");

      const resp = await Request({
        route: "/chat/getChatSelect/?" + "empresa=" + empresa,
      });

      await commit("SET_STATUS", "");

      return resp;
    },

    async getSetorUsuarioSelect({ commit }, chat) {
      await commit("SET_STATUS", "loading");

      const resp = await Request({
        route: "/chat/getSetorSelect/?" + "chat=" + chat,
      });

      await commit("SET_STATUS", "");

      return resp;
    },

    async getMenuUsuarioSelect({ commit }, setor) {
      await commit("SET_STATUS", "loading");

      const resp = await Request({
        route: "/chat/getMenuSelect/?" + "setor=" + setor,
      });

      await commit("SET_STATUS", "");

      return resp;
    },
    async getChatMensagens({ commit }, mensagem_id) {
      await commit("SET_STATUS", "loading");
      await commit("SET_STATUS", "");
      return mensagem_id;
    },
    async sendMessage({ commit }, dados) {
      //seta o status do store para loading
      await commit("SET_STATUS", "loading");
      //faz a requisição para o back para coletar o resultado na variável resp
      const resp = await API.post("/message/", dados);
      // seta o status do store da room para vazio
      await commit("SET_STATUS", "");
      // retorna a resposta da requisição
      return resp;
    },
    async deleteMessage({ commit }, dados) {
      //seta o status do store para loading
      await commit("SET_STATUS", "loading");
      //faz a requisição para o back para coletar o resultado na variável resp
      const resp = await API.delete("/message/", dados);
      // seta o status do store da room para vazio
      await commit("SET_STATUS", "");
      // retorna a resposta da requisição
      return resp;
    },
    async sendMessageReaction({ commit }, dados) {
      //seta o status do store para loading
      await commit("SET_STATUS", "loading");
      //faz a requisição para o back para coletar o resultado na variável resp
      const resp = await API.post("message/reaction", dados);
      // seta o status do store da room para vazio
      await commit("SET_STATUS", "");
      // retorna a resposta da requisição
      return resp;
    },
    async setChatMensagens({ commit }, chats) {
      await commit("ATUALIZA_QUANTIDADE_CHAT", {
        notificacoes: chats.notificacoes,
        conversas: chats.conversas,
      });
      return "";
    },
    async setMensagensChat({ commit }, mensagens) {
      await commit("SET_MENSAGENS", mensagens);
      return "";
    },
    async addMensagensChat({ commit }, mensagem) {
      await commit("ADD_MENSAGENS", mensagem);
      await commit("ATUALIZA_QUANTIDADE_CHAT", {
        notificacoes: this.getters.notificacoes,
        conversas: this.getters.chat_conversas,
      });
      return "";
    },
    async atualizaQuantidadeChat({ commit }) {
      await commit("ATUALIZA_QUANTIDADE_CHAT", {
        notificacoes: this.getters.notificacoes,
        conversas: this.getters.chat_conversas,
      });
      return "";
    },
    async addChat({ commit }, chat) {
      await commit("ADD_CHAT", chat);
      return "";
    },
  },
  getters: {
    chat_empresas: (status) => status.chat_empresas,
    chat_conversas: (status) => status.chat_conversas,
    chat_mensagens: (status) => status.chat_mensagens,
    rooms: (status) => status.rooms,
    users: (status) => status.users,
    messages: (status) => status.messages,
  },
};
